import { create } from 'lib/npm'
import { SIDE_MENU_LIST } from 'components/ui/sidebar/index'
import { DEFAULT_ROUTE } from 'constants'

const initialState = {
    activeTab: DEFAULT_ROUTE,
    sideMenu: SIDE_MENU_LIST,
    isLogOut: false,
    isSidebarOpen: false,
    isNavigation: false,
    isPath: '/',

    // Todo: leave alert popup
    isSafeAlertPopup: false,
    safeAlertPopupData: null,
}

const useCommonStore = create((set, get) => ({

    ...initialState,
    setState: (nextState) => set(() => ({ ...nextState })),
    getState: () => get(),
    resetAlertPopup: () => set(() => ({ isSafeAlertPopup: false, safeAlertPopupData: null, })),

}))

export default useCommonStore