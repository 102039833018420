export default {
    app: {
        name: "Jolt - CMS",
        envType: process.env.REACT_APP_CUSTOM_VARIABLE,
        apiURL: process.env.REACT_APP_END_POINT,
        cmsURL: process.env.REACT_APP_CMS_URL,
        clientURL: process.env.REACT_APP_CLIENT_URL,
        cloudFrontURL: process.env.REACT_APP_CLOUD_FRONT_URL,
    },
    port: 8000,
    secretKey: process.env.REACT_APP_SECRET_KEY
}