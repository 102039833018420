import { React, memo, Navigate, useLocation, Suspense, Outlet } from "lib/react-npm";
import { ErrorBoundary } from "lib/npm";
import ErrorBoundaryFallback from "components/error-boundary-fallback/ErrorBoundaryFallback";
import { Helmet, HelmetProvider } from "react-helmet-async";
import useAuthenticationStore from "store/authentication/useAuthenticationStore";
import PropTypes from "prop-types";
import TopLoader from "components/ui/top-loader/TopLoader";
import WrapperLayout from "components/layout/WrapperLayout";
import ModalProvider from "components/providers/ModalProvider";
import Loader from "components/ui/loader/Loader";

const PrivateRouter = ({ title, allowedRoles }) => {

    const location = useLocation();
    const { auth } = useAuthenticationStore();

    const currentRole = allowedRoles.find((role) => role === auth?.role);

    switch (currentRole) {
        case "Admin":
            return <Wrapper title={title} />

        default:
            switch (true) {
                case !!auth:
                    return <Navigate to="/unauthorized" state={{ from: location }} replace />

                default:
                    return <Navigate to={`/`} state={{ from: location }} replace />
            }
    }
};

const Wrapper = ({ title }) => (
    <HelmetProvider>
        <Helmet>
            <title>{`${title} | Jolt - CMS`}</title>
        </Helmet>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            <TopLoader>
                <WrapperLayout>
                    <ModalProvider>
                        <Suspense fallback={<Loader />}>
                            <Outlet />
                        </Suspense>
                    </ModalProvider>
                </WrapperLayout>
            </TopLoader>
        </ErrorBoundary>
    </HelmetProvider>
)

PrivateRouter.propTypes = {
    title: PropTypes.string.isRequired,
    allowedRoles: PropTypes.string.isRequired,
};

Wrapper.propTypes = {
    title: PropTypes.string.isRequired
};

export default memo(PrivateRouter);