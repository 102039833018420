export const DEFAULT_ROUTE = "/sub-header";

const START_YEAR = 2010;
const END_YEAR = 2030;

export const RELEASE_YEARS = Array.from({ length: END_YEAR - START_YEAR + 1 }, (v, i) => ({
    id: `opt${i + 1}`,
    value: `${START_YEAR + i}`,
    label: `${START_YEAR + i}`,
}))

export const STATUS_OPTIONS = {
    Published: "published",
    Unpublished: "unpublished",
    Draft: "draft",
};