import { React, memo } from 'lib/react-npm'
import PropTypes from "prop-types";
import btn_loader from "assets/images/svg/btn_loader.svg";

function CustomButton({
    type = "submit",
    className = "sky_btn",
    onClick,
    formGroup = '',
    disabled = false,
    isPending = false,
    value = '',
    ...rest
}) {
    return (
        <div className={`form_group ${formGroup}`}>
            <button
                type={type}
                className={`btn ${className}`}
                onClick={onClick}
                disabled={disabled || isPending}
                {...rest}
            >
                {value}
            </button>
            {isPending && <span className="btn_lord white_small_loader"><img src={btn_loader} alt="btn_loader" /></span>}
        </div>
    );
}

CustomButton.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    formGroup: PropTypes.string,
    disabled: PropTypes.bool,
    isPending: PropTypes.bool,
    value: PropTypes.string
};

export default memo(CustomButton);