import React from 'lib/react-npm'
import { Toaster } from 'lib/npm';
import PropTypes from 'prop-types';

export default function ToasterProvider({ children }) {

  return (
    <>
      {children}

      <Toaster
        position='top-right'
        closeButton={true}
        richColors
      />
    </>
  )
}

ToasterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};