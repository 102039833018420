import { CryptoJS, Cookies } from 'lib/npm'
import keys from 'config/keys'
import { expireTime } from 'utils';

const cookies = new Cookies();

class BrowserStorageService {

    // Session Storage Section
    static setSession(key, value) {
        const data = value === undefined ? "" : this.encryption(value);
        window?.sessionStorage.setItem(key, data);
    }

    static getSession(key) {
        const data = window?.sessionStorage.getItem(key);
        return data ? this.decryption(data) : null;
    }

    static removeSession(key) {
        window?.sessionStorage.removeItem(key);
    }

    static removeAllSessions() {
        for (const key in window?.sessionStorage) {
            if (Object.prototype.hasOwnProperty.call(window?.sessionStorage, key)) {
                this.removeSession(key);
            }
        }
    }

    // Local Storage Section
    static setLocal(key, value) {
        const data = value === undefined ? "" : this.encryption(value);
        window?.localStorage.setItem(key, data);
    }

    static getLocal(key) {
        try {
            const data = window?.localStorage.getItem(key);
            return data ? this.decryption(data) : null;
        } catch (error) {
            return null;
        }
    }

    static removeLocal(key) {
        window?.localStorage.removeItem(key);
    }

    static removeAllLocals() {
        for (const key in window?.localStorage) {
            if (Object.prototype.hasOwnProperty.call(window?.localStorage, key)) {
                this.removeLocal(key);
            }
        }
    }

    // Cookie Section
    static setCookie(key, data, expiry) {
        if (key && data) {
            let ciphertext = this.encryption(data);
            cookies.set(key, ciphertext, { path: '/', expires: expiry ?? expireTime() });
        }
    }

    static getCookie(key) {
        const data = cookies.get(key);
        return data ? this.decryption(data) : null;
    }

    static removeCookie(key) {
        if (key) {
            cookies.remove(key, { path: '/', expires: expireTime() });
        }
    }

    // Encrypt Data
    static encryption(data) {
        return keys.app.envType === 'local'
            ? data
            : CryptoJS.AES.encrypt(JSON.stringify(data), keys.secretKey).toString();
    }

    // Decrypt Data
    static decryption(data) {
        if (keys.app.envType === 'local') {
            return data;
        } else {
            let bytes = CryptoJS.AES.decrypt(data, keys.secretKey);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
    }

}

export default BrowserStorageService;
