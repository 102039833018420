import { lazy } from "lib/react-npm";
import roles from "static/roles";

// ! --- [ Public Routes ]
const Authentication = lazy(() => import("pages/authentication/Authentication"));

// ! --- [ Private Routes ]
const SubHeader = lazy(() => import("pages/sub-header/SubHeader"));
const FilmsSupported = lazy(() => import("pages/films-supported/FilmsSupported"));
const FilmsSupportedAdd = lazy(() => import("pages/films-supported/add/Add"));
const Article = lazy(() => import("pages/articles/Articles"));
const ArticleAdd = lazy(() => import("pages/articles/add/Add"));
const SupportContact = lazy(() => import("pages/support-contact/SupportContact"));

const ChangePassword = lazy(() => import("pages/change-password/ChangePassword"));

const { ADMIN } = roles;

export const routeConfig = {
    public: [
        {
            title: "Login",
            path: "/",
            element: Authentication,
            allowedRoles: [ADMIN],
        },
    ],
    private: [
        {
            title: "Sub Header",
            path: "/sub-header",
            element: SubHeader,
            allowedRoles: [ADMIN],
        },

        // ! --- [ Films Support ]
        {
            title: "Films Support",
            path: "/films-support",
            element: FilmsSupported,
            allowedRoles: [ADMIN]
        },
        {
            title: "Films Support Add",
            path: "/films-support/add",
            element: FilmsSupportedAdd,
            allowedRoles: [ADMIN]
        },

        // ! --- [ Article ]
        {
            title: "Article",
            path: "/article",
            element: Article,
            allowedRoles: [ADMIN]
        },
        {
            title: "Article Add",
            path: "/article/add",
            element: ArticleAdd,
            allowedRoles: [ADMIN]
        },

        // ! --- [ Support Contact ]
        {
            title: "Support Contact",
            path: "/support-contact",
            element: SupportContact,
            allowedRoles: [ADMIN]
        },

        // ! --- [ Change password ]
        {
            title: "Change Password",
            path: "/change-password",
            element: ChangePassword,
            allowedRoles: [ADMIN],
        },
    ]
}