import { React } from 'lib/react-npm';
import { QueryClient, QueryClientProvider } from 'lib/npm';
import PropTypes from 'prop-types';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        },
    },
});

export default function ReactQueryProvider({ children }) {


    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}

ReactQueryProvider.propTypes = {
    children: PropTypes.node.isRequired,
};