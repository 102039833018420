import { React } from 'lib/react-npm'
import NoInternetConnection from 'components/providers/NoInternetConnection'
import ReactQueryProvider from 'components/providers/ReactQueryProvider'
import SafeHydrate from 'components/providers/SafeHydrate'
import { NextUIProvider } from 'lib/next-ui'
import ToasterProvider from 'components/providers/ToasterProvider'
import RoutesComponent from 'routes/RoutesComponent'

const App = () => {
  return (
    <SafeHydrate>
      <NoInternetConnection>
        <ReactQueryProvider>
          <NextUIProvider>
            <ToasterProvider>
              <RoutesComponent />
            </ToasterProvider>
          </NextUIProvider>
        </ReactQueryProvider>
      </NoInternetConnection>
    </SafeHydrate>
  )
}

export default App
