import { React, memo, useEffect, useLocation, useNavigate, useRef } from 'lib/react-npm'
import PropTypes from "prop-types";
import { unexpectedError } from 'assets/utils/images';
import CustomButton from 'components/ui/custom-button/CustomButton';
import CustomImage from 'components/ui/custom-image/CustomImage';
import Loader from 'components/ui/loader/Loader';

function ErrorBoundaryFallback({ error, resetErrorBoundary }) {

    const navigate = useNavigate()
    const location = useLocation();
    const errorLocation = useRef(location.pathname);

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    const refreshPage = () => window.location.reload();

    useEffect(() => {
        if (error?.message && chunkFailedMessage.test(error.message)) {
            window.location.reload()
        }
    }, [error]);

    useEffect(() => {
        if (location.pathname !== errorLocation.current) {
            resetErrorBoundary();
        }
    }, [location.pathname])

    if (error?.message && chunkFailedMessage.test(error.message)) {
        return <Loader />
    }

    return (
        <div className="ErrorPage">
            <div className="container">
                <div className="NoAvailbleOrder UnexpectedError">
                    <div className="IconNoOrder"><CustomImage src={unexpectedError} alt="NoOrderIcon" /></div>
                    <h3>An Unexpected <br /> Application Error!</h3>
                    <p className="text_left">We&apos;re sorry, but our application encountered an unexpected error. Our team has been notified, and we&apos;re working diligently to resolve the issue.</p>
                    <p className="try_catch">In the meantime, here are a few things you can try:</p>
                    <div className="d_flex_center w-100 error-btn">
                        <CustomButton type="button" className="sky_border_btn" value="Go back to home" onClick={() => navigate('/')} />
                        <CustomButton type="button" value="Reload page" onClick={refreshPage} />
                    </div>
                </div>
            </div>
        </div>
    )
}

ErrorBoundaryFallback.propTypes = {
    error: PropTypes.any,
    resetErrorBoundary: PropTypes.any,
};

export default memo(ErrorBoundaryFallback)