import { React } from "lib/react-npm";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { onImageErrorHandler } from "utils";
import PropTypes from "prop-types";

export default function CustomImage({
    src,
    alt = "custom image",
    placeholderSrc = "",
    className = "",
    ...rest
}) {
    return (
        <LazyLoadImage
            src={src}
            alt={alt}
            placeholderSrc={placeholderSrc}
            className={className}
            onError={(event) => onImageErrorHandler(event)}
            {...rest}
        />
    );
}

CustomImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    placeholderSrc: PropTypes.string,
    className: PropTypes.string,
};