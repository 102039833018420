import { React, useEffect } from "lib/react-npm";
import { Modal, ModalContent } from "lib/next-ui";
import PropTypes from "prop-types";

function CustomModal({
    children,
    isOpen,
    onOpenChange,
    handleClose,
    radius = 'none',
    placement = 'center',
    size = "5xl",
    backdrop = "blur",
    className = "",
    hideCloseButton = false
}) {

    useEffect(() => {
        const closeOnEscapeKey = (e) => (e?.key === "Escape" ? handleClose() : null);
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);

    return (
        <Modal
            className={className}
            size={size} // "xs", "sm", "md", "lg", "xl", "2xl", "3xl", "4xl", "5xl", "full"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            onClose={handleClose}
            backdrop={backdrop} // "opaque", "blur", "transparent"
            placement={placement}
            radius={radius} // none | sm | md | lg
            hideCloseButton={hideCloseButton}
        >
            <ModalContent>
                {children}
            </ModalContent>
        </Modal>
    );
}

CustomModal.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onOpenChange: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    radius: PropTypes.string,
    placement: PropTypes.string,
    size: PropTypes.string,
    backdrop: PropTypes.string,
    className: PropTypes.string,
    hideCloseButton: PropTypes.bool,
};

export default CustomModal;