import { React, memo, Suspense, Navigate, Outlet, useLocation, useMemo } from "lib/react-npm";
import PropTypes from "prop-types";
import { ErrorBoundary, } from 'lib/npm';
import TopLoader from "components/ui/top-loader/TopLoader";
import Loader from "components/ui/loader/Loader";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useAuthenticationStore from "store/authentication/useAuthenticationStore";
import ErrorBoundaryFallback from "components/error-boundary-fallback/ErrorBoundaryFallback";
import { DEFAULT_ROUTE } from "constants";

const PublicRouter = ({ title }) => {

    const location = useLocation();
    const { auth } = useAuthenticationStore();

    const helmet = useMemo(() => (
        <Helmet>
            <title>{`${title} | Jolt - CMS`}</title>
        </Helmet>
    ), [title]);

    switch (true) {
        case !!auth:
            return (
                <Navigate to={DEFAULT_ROUTE} state={{ from: location }} replace />
            );

        default:
            return (
                <HelmetProvider context={{}}>
                    {helmet}
                    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                        <TopLoader>
                            <Suspense fallback={<Loader />}>
                                <Outlet />
                            </Suspense>
                        </TopLoader>
                    </ErrorBoundary>
                </HelmetProvider>
            );
    }
};

PublicRouter.propTypes = {
    title: PropTypes.string.isRequired
};

export default memo(PublicRouter);