import { React } from 'lib/react-npm';
import SideMenu from 'components/ui/sidebar/SideMenu';
import PropTypes from "prop-types";
import useCommonStore from 'store/common-store/useCommonStore';

function WrapperLayout({ children }) {

    const { isSidebarOpen } = useCommonStore();

    return (
        <div className="d_flex">

            {/* SideMenu */}
            <SideMenu />

            {/* Sidebar's Content */}
            <div className={`right_wrapper ${isSidebarOpen ? 'wrapper_close' : ''}`}>
                <div className="sidebar_content">
                    {children}
                </div>
            </div>
        </div>

    )
}

WrapperLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default WrapperLayout