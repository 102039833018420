import { actionDeleteIcon, editIcon, JoltSpaceDefaultImage } from "assets/utils/images";
import BrowserStorageService from "services/shared/browserStorage.service";
import NotificationService from "services/shared/notification.service";
import CommonMessage from "static/CommonMessage";

export const expireTime = () => new Date(Date.now() + 15 * (24 * (60 * 60 * 1000))); // Token expiry time

export const onImageErrorHandler = ({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = JoltSpaceDefaultImage;
}

export const getQuery = (searchParams, query = 'query') => {
    const value = searchParams.get(query);
    if (value) {
        return BrowserStorageService.decryption(decodeURIComponent(value));
    }
    return {};
};

export const sendQuery = (nextQuery = {}) => encodeURIComponent(BrowserStorageService.encryption(nextQuery));

export function removeEmptyKeysToObject(obj) {
    const newObj = {};
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (value !== undefined && value !== null && value !== '') {
            newObj[key] = value;
        }
    });
    return newObj;
}

export function objectToQueryString(obj, removeNulls = false) {
    const keys = Object.keys(obj);

    const keyValuePairs = keys.map(key => {
        if (removeNulls) {
            return obj[key] ? encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]) : '';
        } else {
            return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
        }
    }).filter(Boolean);

    return keyValuePairs.join('&');
}

export function queryParamsToObject(queryString) {
    queryString = queryString.startsWith('?') ? queryString.slice(1) : queryString;

    const pairs = queryString.split('&');
    const result = {};

    for (const pair of pairs) {
        const [key, value] = pair.split('=');

        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value || '');

        if (Object.prototype.hasOwnProperty.call(result, decodedKey)) {
            result[decodedKey] = decodedValue;
        } else {
            result[decodedKey] = decodedValue;
        }
    }
    return result;
}

export const actionData = [
    { id: 1, displayName: 'Edit', icon: editIcon },
    { id: 2, displayName: 'Delete', icon: actionDeleteIcon }
]

export const isMobileOrTablet = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const maxTouchPoints = window.navigator.maxTouchPoints || 0;

    const keys = [
        'mobile', 'android', 'iPod Simulator', 'iPhone Simulator', 'iPad Simulator',
        'iphone', 'iPad', 'ipad', 'iPod', 'ipod', 'blackberry',
        'iemobile', 'opera mini', 'windows phone', 'tablet', 'playbook', 'kindle'
    ];

    for (let i = 0; i < keys.length; i++) {
        if (userAgent.indexOf(keys[i]) !== -1) return true;
    }

    if (/macintosh/.test(userAgent) && maxTouchPoints > 0) {
        return true;
    }

    return false;
}

export const imageUploadHandler = async (
    event,
    imageData
) => {

    const {
        maxWidth = 1920,
        maxHeight = 1080,

        mimWidth = 200,
        minHeight = 200,

        imageHeight = 0,

        isMinimum = false,
        isSquare = false,
        isWidthHeight = true,
        isHeight = false,
        isAllowFileSize = 5
    } = imageData || {};

    const [selectedFile] = event.target.files;
    const allowedMediaExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();

    const imageUrl = URL.createObjectURL(selectedFile);
    const reader = new Image();

    let isMedia = allowedMediaExtensions.includes(`${fileExtension}`);
    let isFileSize = (selectedFile.size / 1024 / 1024).toFixed();


    if (!isMedia) {
        return NotificationService.showWarningMessage(CommonMessage.validImageFormate)
    }

    if (!(isFileSize <= isAllowFileSize)) {
        return NotificationService.showWarningMessage(CommonMessage.imageSizeFormate)
    }

    const loadPromise = new Promise((resolve, reject) => {
        reader.onload = () => {
            URL.revokeObjectURL(imageUrl);
            resolve({ width: reader.width, height: reader.height, src: imageUrl });
        };

        reader.onerror = () => {
            reject(new Error(CommonMessage.imageError));
        };

        reader.src = imageUrl;
    });

    const { width, height } = await loadPromise;

    if (isWidthHeight) {

        if (width > maxWidth || height > maxHeight) {
            return NotificationService.showWarningMessage(`Please upload an image of ${maxWidth} by ${maxHeight} pixels.`);
        }

        if (isMinimum) {

            if (width < mimWidth || height < minHeight) {
                return NotificationService.showWarningMessage(`Please upload an image minimum of ${mimWidth} by ${minHeight} pixels.`);
            }

        }

    }

    if (isHeight) {

        if (height > imageHeight) {
            return NotificationService.showWarningMessage(`Upload image height must be ${imageHeight} pixels.`);
        }

    }

    if (isSquare) {

        if (width !== height) {
            return NotificationService.showWarningMessage(CommonMessage.squareShapedImage);

        }

    }

    return selectedFile
}