import { React } from 'lib/react-npm';
import { toast } from 'lib/npm';
import CommonMessage from 'static/CommonMessage';
import { ReactComponent as CheckmarkIcon } from "assets/images/svg/checkmark_icon.svg";
import { ReactComponent as CloseIcon } from "assets/images/svg/close_icon.svg";
import { ReactComponent as AlertIcon } from "assets/images/svg/alert_icon.svg";

const AUTO_CLOSE_TIMEOUT = 7000;

class NotificationService {
    static showSuccessMessage(titleOrMessage, description, duration) {
        if (!description && !titleOrMessage) {
            titleOrMessage = `${CommonMessage.Success}`;
            description = `${CommonMessage.SuccessOperation}`;
        } else if (!description) {
            description = '';
        }

        if (typeof duration === 'undefined') {
            duration = AUTO_CLOSE_TIMEOUT;
        }

        const toastOptions = {
            description,
            duration,
            className: 'my-className'
        };

        toast.custom((t) => (
            <div className='bg-white toast success_toast position_relative'>
                <div className='flex align_items_center'>
                    <CheckmarkIcon />
                    <span>{titleOrMessage}</span>
                    <button
                        className='close_icon position_absolute'
                        onClick={() => toast.dismiss(t)}
                        aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                </div>
            </div>
        ), toastOptions);
    }

    static showErrorMessage(titleOrMessage, description, duration) {
        if (!description && !titleOrMessage) {
            titleOrMessage = `${CommonMessage.Error}`;
            description = `${CommonMessage.ErrorOperation}`;
        } else if (!description) {
            description = ''; // Removed self-assignment
        }

        if (typeof duration === 'undefined') {
            duration = AUTO_CLOSE_TIMEOUT;
        }

        const toastOptions = {
            description,
            duration,
        };

        toast.custom((t) => (
            <div className='bg-white toast success_toast position_relative'>
                <div className='flex align_items_center'>
                    <AlertIcon className='alert_icon' />
                    <span>{titleOrMessage}</span>
                    <button
                        className='close_icon position_absolute'
                        onClick={() => toast.dismiss(t)}
                        aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                </div>
            </div>
        ), toastOptions);
    }

    static showInfoMessage(titleOrMessage, description, duration) {
        if (!description && !titleOrMessage) {
            titleOrMessage = `${CommonMessage.Info}`;
            description = `${CommonMessage.InfoOperation}`;
        } else if (!description) {
            description = ''; // Removed self-assignment
        }

        if (typeof duration === 'undefined') {
            duration = AUTO_CLOSE_TIMEOUT;
        }

        const toastOptions = {
            description,
            duration,
        };

        toast.info(titleOrMessage, toastOptions);
    }

    static showWarningMessage(titleOrMessage, description, duration) {
        if (!description && !titleOrMessage) {
            titleOrMessage = `${CommonMessage.Warning}`;
            description = `${CommonMessage.WarningOperation}`;
        } else if (!description) {
            description = ''; // Removed self-assignment
        }

        if (typeof duration === 'undefined') {
            duration = AUTO_CLOSE_TIMEOUT;
        }

        const toastOptions = {
            description,
            duration,
        };

        toast.custom((t) => (
            <div className='bg-white toast success_toast position_relative warning_toast'>
                <div className='flex align_items_center'>
                    <AlertIcon className='alert_icon' />
                    <span>{titleOrMessage}</span>
                    <button
                        className='close_icon position_absolute'
                        onClick={() => toast.dismiss(t)}
                        aria-label="Close"
                    >
                        <CloseIcon />
                    </button>
                </div>
            </div>
        ), toastOptions);
    }
}

export default NotificationService;