
import { React, memo } from 'lib/react-npm';
import PropTypes from 'prop-types';

const SafeHydrate = ({ children }) => {
    if (typeof document === 'undefined') return null;

    return (
        <div suppressHydrationWarning>
            {children}
        </div>
    );
};

SafeHydrate.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(SafeHydrate);