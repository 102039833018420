import { React } from 'lib/react-npm';
import LogOutAlert from 'components/popup/log-out-alert/LogOutAlert';
import PropTypes from "prop-types";
import LeaveAlert from 'components/popup/leave-alert copy/LeaveAlert';
import useCommonStore from 'store/common-store/useCommonStore';

export default function ModalProvider({ children }) {

    const { safeAlertPopupData, setState, resetAlertPopup } = useCommonStore();

    const leaveSafeAlert = () => {
        safeAlertPopupData()
        resetAlertPopup()
    }

    return (
        <>
            {children}

            <LogOutAlert />

            {
                safeAlertPopupData &&

                <LeaveAlert
                    leaveSafeAlert={leaveSafeAlert}
                    closeSafeAlert={() => setState({ safeAlertPopupData: null })}
                />
            }
        </>
    )
}

ModalProvider.propTypes = {
    children: PropTypes.node.isRequired
};