import { filmsIcon } from "assets/utils/images";
import roles from "static/roles";

const { ADMIN } = roles;

export const SIDE_MENU_LIST = [
    {
        title: 'Sub Header',
        value: 'sub header',
        path: '/sub-header',
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        layout: 'mainRoute',
        includesPathe: ['/sub-header']
    },
    {
        title: 'Films Support',
        value: 'films support',
        path: '/films-support',
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        layout: 'mainRoute',
        includesPathe: ['/films-support', '/films-support/add']
    },
    {
        title: 'Article',
        value: 'article',
        path: '/article',
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        layout: 'mainRoute',
        includesPathe: ['/article', '/article/add']
    },
    {
        title: 'Support Contact',
        value: 'support-contact',
        path: '/support-contact',
        icon: filmsIcon,
        allowedRoles: [ADMIN],
        layout: 'mainRoute',
        includesPathe: ['/support-contact']
    },
    {
        title: 'Change Password',
        value: 'ChangePassword',
        path: '/change-password',
        allowedRoles: []
    }
]