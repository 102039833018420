import { React } from 'lib/react-npm';
import CustomModal from "components/ui/modal/Modal"
import CustomImage from "components/ui/custom-image/CustomImage";
import { alertIcon } from 'assets/utils/images';
import CustomButton from 'components/ui/custom-button/CustomButton';
import PropTypes from "prop-types";

export default function LeaveAlert({ leaveSafeAlert, closeSafeAlert }) {
  return (
    <CustomModal isOpen hideCloseButton={true} handleClose={() => closeSafeAlert()} className='popup alert_popup'>
      <div className='popup_wrapper'>
        <CustomImage src={alertIcon} alt='alertIcon' className='popup_image m_auto' />
        <div className='popup_content popup_inner_content'>
          <h3>Alert</h3>
          <span>{`You're about to leave this page without saving your changes. Do you want to leave?`}</span>
        </div>
        <div className='popup_btns d_flex justify_content_center'>
          <CustomButton type="button" className="sky_border_btn mr_24" value="no" onClick={() => closeSafeAlert()} />
          <CustomButton type="button" className="sky_border_btn " value="yes" onClick={() => leaveSafeAlert()} />
        </div>
      </div>
    </CustomModal>
  )
}

LeaveAlert.propTypes = {
  leaveSafeAlert: PropTypes.func,
  closeSafeAlert: PropTypes.func
}
