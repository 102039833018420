import { React, useNavigate } from 'lib/react-npm';
import { notFound } from 'assets/utils/images';
import CustomImage from 'components/ui/custom-image/CustomImage';
import keys from 'config/keys';
import { DEFAULT_ROUTE } from 'constants';

export default function NotFound() {

  const title = 'Page Not Found';
  const description = 'You may have reached this page by error.'

  const navigate = useNavigate()
  const { cmsURL } = keys.app;

  return (
    <div className="not_found">
      <div className='container'>
        <div className='not_found_wrapper'>
          <div className="not found_img">
            <CustomImage src={notFound} alt='notFound' />
          </div>
          <div className='error_msg_desc'>
            <h1>{title}</h1>
            <p>{description}  Please go back to
              &nbsp;
              <span
                role="button"
                tabIndex="0"
                onClick={() => navigate(DEFAULT_ROUTE)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') navigate(DEFAULT_ROUTE);
                }}
              >
                {cmsURL}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}