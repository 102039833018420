import { React, createBrowserRouter, createRoutesFromElements, memo, Route, RouterProvider } from 'lib/react-npm'
import { routeConfig } from './routes';
import PublicRouter from './PublicRouter';
import PrivateRouter from './PrivateRouter';
import NotFound from 'pages/not-found/NotFound';

function RoutesComponent() {

    const render = () => {
        return (
            <>
                {/* Public Route List  */}
                {routeConfig.public.map(({ title, path, element: Element, ...rest }) => (
                    <Route key={title} element={<PublicRouter title={title} {...rest} />}>
                        <Route path={path} element={<Element />} />
                    </Route>
                ))}

                {/* Private Route List */}
                {routeConfig.private.map(({ title, path, allowedRoles, element: Element, ...rest }) => (
                    <Route key={title} element={<PrivateRouter title={title} allowedRoles={allowedRoles} {...rest} />}>
                        <Route path={path} element={<Element />} />
                    </Route>
                ))}

                <Route path={'/*'} element={<NotFound />} />
            </>
        )
    }

    const router = createBrowserRouter(createRoutesFromElements(render()));

    return <RouterProvider router={router} />
}

export default memo(RoutesComponent);