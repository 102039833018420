import { React, useLocation, useState, useRef, useEffect } from "lib/react-npm";
import { LoadingBar } from 'lib/npm';
import PropTypes from 'prop-types';

export default function TopLoader({ children }) {

    const [previousLocation, setPreviousLocation] = useState("")
    const { pathname } = useLocation();

    const ref = useRef(null);

    useEffect(() => {

        setPreviousLocation(pathname);

        ref.current.continuousStart()

        if (pathname === previousLocation) {
            setPreviousLocation('');
        }

    }, [pathname])

    useEffect(() => {
        ref.current.complete();
    }, [previousLocation])

    return (
        <>
            <LoadingBar height="5px" color="#77D4E1" ref={ref} shadow={true} />
            {children}
        </>
    )
}

TopLoader.propTypes = {
    children: PropTypes.node.isRequired,
};