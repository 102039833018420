import { logoutIcon } from "assets/utils/images";
import CustomImage from "components/ui/custom-image/CustomImage";
import CustomModal from "components/ui/modal/Modal";
import { SIDE_MENU_LIST } from "components/ui/sidebar";
import { React } from "lib/react-npm";
import BrowserStorageService from "services/shared/browserStorage.service";
import useAuthenticationStore from "store/authentication/useAuthenticationStore";
import useCommonStore from "store/common-store/useCommonStore";

export default function LogOutAlert() {
    const { isLogOut, setState } = useCommonStore();
    const { setState: setAuth } = useAuthenticationStore()

    const handleCancel = () => {
        setState({ isLogOut: false })
    }

    const handleLogOut = () => {
        BrowserStorageService.removeCookie('auth')
        setAuth({ auth: null })
        setState({ sideMenu: [...SIDE_MENU_LIST] })
        handleCancel()
    }

    return (
        <CustomModal isOpen={isLogOut} handleClose={() => handleCancel()} className='popup logOut_popup'>
            <div className='popup_wrapper'>
                <div className='popup_content'>
                    <h3>Are you sure want to Log Out?</h3>
                </div>
                <CustomImage src={logoutIcon} alt='logoutIcon' className='popup_image m_auto popup_inner_content' />
                <div className='popup_btns d_flex justify_content_center'>
                    <button className='btn sky_border_btn mr_24' onClick={() => handleCancel()}>CANCEL</button>
                    <button className='btn danger_btn' onClick={() => handleLogOut()}>LOG OUT</button>
                </div>
            </div>
        </CustomModal>
    )
}