import { React, useLocation, useEffect, useNavigate } from 'lib/react-npm';
import { WebLogo, closeIcon } from 'assets/utils/images';
import useAuthenticationStore from 'store/authentication/useAuthenticationStore';
import useCommonStore from 'store/common-store/useCommonStore';
import { ReactComponent as LockIcon } from "assets/images/svg/lock_icon.svg";
import { ReactComponent as LogoutIcon } from "assets/images/svg/logout_icon.svg";
import PropTypes from "prop-types";
import CustomImage from '../custom-image/CustomImage';
import roles from 'static/roles';
import { DEFAULT_ROUTE } from 'constants';

const SideMenu = () => {

    const navigate = useNavigate();

    const { pathname } = useLocation();
    const { auth } = useAuthenticationStore();

    const { activeTab, sideMenu, setState, isSidebarOpen } = useCommonStore();

    const pathArray = pathname.split('/');

    useEffect(() => {
        const modal = document.getElementById('overlayItem');

        window.onclick = function (event) {
            if (event.target == modal) {
                setState({ isSidebarOpen: false })
            }
        }

    }, [])

    useEffect(() => {
        const element = document?.getElementsByTagName('html')?.[0];

        if (!!element && isSidebarOpen) {
            element.style.overflow = 'hidden';
        }

        return () => {
            element.style.overflow = '';
        };
    }, [isSidebarOpen]);

    const handleLogOut = () => { setState({ isLogOut: true }) }

    const handleNavigate = (path) => {
        if (isSidebarOpen) {
            setState({ isSidebarOpen: !isSidebarOpen })
        }
        navigate(path)
    }

    const pageChanger = (activeTab) => {
        setState({ activeTab })
    }

    return (
        <div className={`left_wrapper ${isSidebarOpen ? 'wrapper_close' : ''}`}>

            <div id='overlayItem' className={`bg_overlay ${isSidebarOpen ? 'd_block' : 'd_none'}`}></div>

            <div className={`sidebar ${isSidebarOpen ? 'close' : ''}`}>
                <div className='website_logo flex items-center'>
                    {

                        (pathArray.length <= 2)

                            ? (
                                <button onClick={() => handleNavigate(DEFAULT_ROUTE)} className='logo_btn'>
                                    <CustomImage src={WebLogo} alt='WebLogo' className='weblogo_btn' />
                                </button>
                            )
                            : (
                                <button className='exit_btn items-center' onClick={() => handleNavigate(activeTab)}>
                                    <div className='close_icon'><CustomImage src={closeIcon} alt='closeIcon' /></div>Exit
                                </button>
                            )

                    }
                </div>

                <div className='sidebar_links'>
                    <div className='sidebar_links_items mt_24'>

                        {

                            sideMenu.map(({ path, allowedRoles, ...rest }) => (
                                allowedRoles.includes(auth?.role ?? roles.USER)
                                    ? <SideMenuItem
                                        key={path}
                                        path={path}
                                        pathname={pathname}
                                        activeTab={activeTab}
                                        handleNavigate={handleNavigate}
                                        pageChanger={pageChanger}
                                        {...rest}
                                    />
                                    : null
                            ))

                        }

                    </div>

                    <div className='sidebar_footer_links '>
                        <label className='footer_link_label' htmlFor='Profile'>Profile</label>
                        <button
                            className={`flex items-center w-full ${(pathname === '/change-password') ? 'active' : ''}`}
                            onClick={() => handleNavigate('/change-password')}
                            aria-label='Change Password'
                        >
                            <LockIcon />
                            Change password
                        </button>
                        <button
                            className="flex items-center danger w-full"
                            onClick={() => handleLogOut()}
                            aria-label="Log Out"
                        >
                            <LogoutIcon />
                            Log Out
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

SideMenu.propTypes = {
    sideMenu: PropTypes.array.isRequired,
    activeTab: PropTypes.string.isRequired,
    isSidebarOpen: PropTypes.bool.isRequired,
};

export default SideMenu


const SideMenuItem = ({
    path,
    pathname,
    title,
    icon: Icon,
    layout,
    isRequired,
    handleNavigate,
    pageChanger,
    includesPathe = []
}) => {

    switch (layout) {
        case 'subRoute':
            return (
                <button
                    className={`sidebar_inner_links d_flex ${pathname.includes(path) ? 'active' : ''}`}
                    onClick={() => handleNavigate(path)}
                    aria-label={title}
                >
                    {title}
                    {isRequired ? <span className='ml_auto'>*</span> : ''}
                </button>
            )

        default:
            return (
                <button
                    className={`w-full ${includesPathe.includes(pathname) ? 'active' : ''}`}
                    onClick={(() => { pageChanger(path); handleNavigate(path); })}
                    aria-label={title}
                >
                    <CustomImage src={Icon} alt={title} className='' />
                    {title}
                </button>
            )

    }
}

SideMenuItem.propTypes = {
    path: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    pageChanger: PropTypes.func.isRequired,
    layout: PropTypes.string,
    isRequired: PropTypes.bool,
    includesPathe: PropTypes.array,
    handleNavigate: PropTypes.func.isRequired
};